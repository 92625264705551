.PictureWallImage:hover > .PW_Buttons{
    opacity: 100;
    color:#565049;
}
.PictureWallImage > .PW_Buttons{
    opacity: 0;
    /* color:#FF0000; */
}
.PictureWallImage:hover > .PW_Overlay{
    opacity: 30%;
    background-color: black;
}
.PictureWallUploadContainer{
    border:1px dashed #d9d9d9;
}
.PictureWallUploadContainer:hover,.PictureWallUploadContainer:active {
    border:1px dashed #88AAFF;
}

.PictureWallUploadContainer > span{
    outline:none;
}
.PW_Buttons > a,.PW_Buttons > a:hover{
    text-decoration: none;
    color:#565049;
}
.PW_Buttons > a,.PW_Buttons > i{
    padding: 4px;
}