.breadcrumb {
  display: inline-block;
  padding: 0;
  margin: 0;
  background: transparent;
  overflow: hidden;
  list-style: none;
}

.breadcrumb li {
  float: left;
  padding: 8px 15px 8px 20px;
  background: #fdec82;
  font-size: 14px;
  font-weight: bold;
  color: #777;
  position: relative;
}

.breadcrumb li:first-child {
  background: #fdf9cc;
}

.breadcrumb li:last-child {
  background: #fddc05;
  margin-right: 18px;
}

.breadcrumb li:before {
  display: none;
}

.breadcrumb li:after {
  content: "";
  display: block;
  border-left: 18px solid #fdec82;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  position: absolute;
  top: 0;
  right: -18px;
  z-index: 1;
}

.breadcrumb li:first-child:after {
  border-left-color: #fdf9cc;
}

.breadcrumb li:last-child:after {
  border-left-color: #fddc05;
}

.breadcrumb li a {
  font-size: 14px;
  font-weight: bold;
  color: #777;
}

@media only screen and (max-width: 479px) {
  .breadcrumb li {
    padding: 8px 15px 8px 30px;
  }
}
