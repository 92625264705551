.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #455b91;
  color: #f5f5f5;
  cursor: pointer;
}

.suggestions li:not(:last-of-type) {
  /* border-bottom: 1px solid #999; */
}
