.category-picker {
    padding: 8px 0px;

    .pick-box-container {
        display: flex;

        .pick-box {
            top: 100%;
            padding: 8px;
            left: 0;
            width: 500px;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            z-index: 1;
        }

        .category-list {
            display: grid;
            width: 500px;
            justify-content: space-between;
            flex-direction: inherit;
            grid-column: inherit;
            grid-template-columns: repeat(5, 100px);

            .category-item {
                display: inline-block;
                cursor: pointer;
                margin: 4px;
                background-color: rgb(241, 241, 241);
                text-align: center;
                border-radius: 4px;

                &:hover {
                    color: #e69e41;
                    // background-color: #0e0d0d;
                    box-shadow: 0px 0px 3px #e69e41;
                }

                .category {
                    display: inline-block;
                }
            }
        }
    }
}